import { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";
import toast from "react-hot-toast";

const useSocketUserdata = (userId) => {
  const accType = localStorage.getItem("account");
  const [data, setData] = useState(null);
  const socketRef = useRef(null);

  useEffect(() => {
    if (
      userId === 0 ||
      userId === null ||
      userId === undefined ||
      userId === ""
    ) {
      return;
    }

    socketRef.current = io("https://api.jmd20.com");

    const handleConnect = () => {
      console.log("Connected to server");

      socketRef.current.emit("user_data", {
        userId: userId,
        accType: accType,
      });

      const handleUserData = (userData) => {
        setData(userData);
      };

      socketRef.current.on(`user_data_${userId}`, handleUserData);

      // Listen for logout event triggered from the server
      socketRef.current.on("logout", () => {
        toast.error("You have been logged out from this device.");

        localStorage.clear();
        sessionStorage.clear();
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      });

      return () => {
        socketRef.current.off(`user_data_${userId}`, handleUserData);
        socketRef.current.off("logout");
      };
    };

    const handleError = (error) => {
      console.error("Socket error: ", error);
    };

    socketRef.current.on("connect", handleConnect);
    socketRef.current.on("error", handleError);

    return () => {
      socketRef.current.disconnect();
      socketRef.current.off("connect", handleConnect);
      socketRef.current.off("error", handleError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return data;
};

export default useSocketUserdata;
